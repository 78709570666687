import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { ICONS } from '@emobg/vue-base';
import crm from '@domains/CRM/router/CRMRouterMap';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
import fleetRoutes from '@domains/Fleet/router/FleetRouterMap';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import fleet from '../../Carsharing/router/FleetRouterMap';

export const fallbackProps = {
  placeholder: 'Search Backoffice',
};

export function omnisearchConfig() {
  return [
    {
      algolia: {
        index: ALGOLIA_INDEXES.users,
      },
      badge: {
        icon: ICONS.personal,
        text: upperFirst(this.$t('Common.Business.user')),
      },
      primary: {
        to: result => ({
          name: crm.users.detail.index,
          params: {
            userUuid: result.uuid,
          },
        }),
        text: result => `${result.first_name} ${result.last_name}`,
        class: 'emobg-link-primary emobg-body-2',
      },
      secondary: {
        text: result => result.email,
        class: 'emobg-color-ink',
      },
    },
    {
      algolia: {
        index: ALGOLIA_INDEXES.drivingLicenses,
      },
      badge: {
        icon: ICONS.drivingLicense,
        text: upperFirst(this.$t('Common.Business.driving_license')),
      },
      primary: {
        to: result => ({
          name: crm.users.detail.account,
          params: {
            userUuid: result.user_uuid,
          },
        }),
        text: result => `${result.user_name}`,
        class: 'emobg-link-primary emobg-body-2',
      },
      secondary: {
        text: result => result.license_number,
        class: 'emobg-color-ink',
      },
    },
    {
      algolia: {
        index: ALGOLIA_INDEXES.companies,
      },
      badge: {
        icon: ICONS.business,
        text: upperFirst(this.$t('Common.Business.company')),
      },
      primary: {
        href: result => companyProfileUrl(result.uuid),
        text: result => result.commercial_name,
        class: 'emobg-link-primary emobg-body-2',
      },
    },
    {
      algolia: {
        index: ALGOLIA_INDEXES.csBookings,
      },
      badge: {
        icon: ICONS.calendar,
        text: upperFirst(this.$t('Common.Business.booking')),
      },
      primary: {
        to: result => ({
          name: carsharingRoutes.bookings.detail.index,
          params: {
            bookingUuid: result.uuid,
          },
        }),
        text: result => result.user_name,
        class: 'emobg-link-primary emobg-body-2',
      },
      secondary: {
        text: result => `#${result.id}`,
        class: 'emobg-color-ink',
      },
    },
    {
      algolia: {
        index: ALGOLIA_INDEXES.locations,
      },
      badge: {
        icon: ICONS.location,
        text: upperFirst(this.$t('Common.Business.location')),
      },
      primary: {
        to: result => ({
          name: fleetRoutes.locations.detail.index,
          params: {
            locationUuid: result.uuid,
          },
        }),
        text: result => result.name,
        class: 'emobg-link-primary emobg-body-2',
      },
    },
    {
      algolia: {
        index: ALGOLIA_INDEXES.vehicles,
      },
      badge: {
        icon: ICONS.carsharing,
        text: upperFirst(this.$t('Common.Business.vehicle')),
      },
      primary: {
        to: result => ({
          name: fleet.vehicles.detail.details,
          params: {
            vehicleUuid: result.uuid,
          },
        }),
        text: result => `${result.license_plate} ${result.vehicle_brand} ${result.vehicle_model}`,
        class: 'emobg-link-primary emobg-body-2',
      },
    },
    {
      algolia: {
        index: ALGOLIA_INDEXES.invoices,
      },
      badge: {
        icon: ICONS.invoicing,
        text: upperFirst(this.$t('Common.Business.invoice')),
      },
      primary: {
        href: result => `/invoicing/invoices/${result.uuid}/summary/details`,
        text: result => `${result.number} ${result.customer_name}`,
        class: 'emobg-link-primary emobg-body-2',
      },
      secondary: {
        text: result => upperFirst(result.status),
        class: result => (get(result, 'status') === 'closed' ? 'emobg-color-danger' : 'emobg-color-success'),
      },
    },
    {
      algolia: {
        index: ALGOLIA_INDEXES.caseEvents,
      },
      badge: {
        icon: ICONS.pricingPlan,
        text: upperFirst(this.$t('Common.Business.case')),
      },
      primary: {
        href: result => `/cases-service/case-events/${result.uuid}`,
        text: result => `#${result.id} ${result.case_type_name_translations.en_GB}`,
        class: 'emobg-link-primary emobg-body-2',
      },
    },
    {
      algolia: {
        index: ALGOLIA_INDEXES.vehicleDamages,
      },
      badge: {
        icon: ICONS.autoCrane,
        text: upperFirst(this.$t('Common.Business.damages')),
      },
      primary: {
        href: result => `/fleet/vehicles/${result.vehicle_id}/vehicle-damages/${result.id}`,
        to: result => ({
          name: fleet.damages.edit,
          params: {
            vehicleUuid: result.vehicle.uuid,
            damageUuid: result.uuid,
          },
        }),
        text: result => `#${result.id} ${result.vehicle.license_plate}`,
        class: 'emobg-link-primary emobg-body-2',
      },
    },
    {
      algolia: {
        index: ALGOLIA_INDEXES.reports,
      },
      badge: {
        icon: ICONS.report,
        text: upperFirst(this.$t('Common.Business.reports')),
      },
      primary: {
        href: result => `/business-intelligence/reports/${result.uuid}`,
        text: result => `${result.group} ${result.name}`,
        class: 'emobg-link-primary emobg-body-2',
      },
    },
  ];
}
