import moment from 'moment-timezone';
import { DATE_FORMAT } from '@emobg/web-utils';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { INVOICING_PERMISSIONS } from '@domains/Invoicing/const/permissions';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import CarsharingRouterMap from '@domains/Carsharing/router/CarsharingRouterMap';
import FleetRouterMap from '@domains/Carsharing/router/FleetRouterMap';
import CrmRouterMap from '@domains/CRM/router/CRMRouterMap';
import { PermissionLink } from '@/components';
import { AlertsTemplate } from '@/templates';
import InvoicesListTemplate from '../components/BookingInvoicesKmsMismatch/components/InvoicesListTemplate';

const invoiceProp = alert => ({
  class: 'emobg-font-weight-semibold',
  href: `/invoicing/invoices/${alert.invoiceId}/summary/details`,
  target: '_blank',
  linkPermissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
  text: alert.reference,
});

export const WIDGET_SCHEMAS = {
  notConfirmedBookings: [
    {
      header: 'ID',
      component: PermissionLink,
      props: booking => ({
        to: {
          name: CarsharingRouterMap.bookings.detail.index,
          params: { bookingUuid: booking.uuid },
        },
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
        text: `#${booking.id}`,
        class: 'emobg-font-weight-semibold',
      }),
      minWidth: 100,
      width: 100,
    },
    {
      header: 'Location',
      template: 'location',
    },
    {
      header: 'Start',
      template: ({ start }) => moment(start).format(DATE_FORMAT.defaultExtended),
    },
    {
      header: 'End',
      template: ({ end }) => moment(end).format(DATE_FORMAT.defaultExtended),
    },
    {
      header: 'Vehicle',
      component: PermissionLink,
      props: booking => ({
        to: {
          name: FleetRouterMap.vehicles.detail.details,
          params: { vehicleUuid: booking.vehicleUuid },
        },
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        text: booking.vehicle,
        class: 'emobg-font-weight-semibold',
      }),
    },
    {
      header: 'Issue',
      component: AlertsTemplate,
      collapsed: true,
      props: result => ({
        result,
        alerts: JSON.parse(result.alertReason),
      }),
    },
    {
      header: 'Customer',
      component: PermissionLink,
      props: booking => ({
        to: {
          name: CrmRouterMap.users.detail.bookings,
          params: { userUuid: booking.userUuid },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: booking.customer,
        class: 'emobg-font-weight-semibold',
      }),
    },
  ],
  openedInvoicesWithFinishedBookings: [
    {
      header: 'Invoice',
      component: PermissionLink,
      props: alert => ({
        href: `/invoicing/invoices/${alert.invoiceId}/summary/details`,
        target: '_blank',
        linkPermissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
        text: alert.reference,
      }),
    },
    {
      header: 'Date',
      template: ({ date }) => moment(date).format(DATE_FORMAT.dob),
    },
    {
      header: 'Booking',
      component: PermissionLink,
      props: alert => ({
        to: {
          name: CarsharingRouterMap.bookings.detail.index,
          params: { bookingUuid: alert.bookingUuid },
        },
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
        text: alert.bookingId,
      }),
    },
  ],
  invoicesWithoutNif: [
    {
      header: 'Invoice',
      component: PermissionLink,
      props: invoiceProp,
    },
    {
      header: 'Customer',
      component: PermissionLink,
      props: invoice => {
        const to = invoice.companyId || { name: CrmRouterMap.users.detail.index, params: { userUuid: invoice.userUuid } };
        const href = invoice.userUuid || `/user-company/companies/${invoice.companyId}`;
        const target = invoice.userUuid || '_blank';
        const linkPermissions = [invoice.userUuid ? CRM_PERMISSIONS.viewCrmUsers : CRM_PERMISSIONS.viewCrmCompanies];
        return {
          class: 'emobg-font-weight-semibold',
          to,
          href,
          target,
          linkPermissions,
          text: invoice.customerName,
        };
      },
    },
  ],
  finalAndOpenedInvoices: [
    {
      header: 'Invoice',
      component: PermissionLink,
      props: invoiceProp,
    },
  ],
  nonCorrelativeInvoices: [
    {
      header: 'Invoice',
      component: PermissionLink,
      props: invoiceProp,
    },
    {
      header: 'Wrong date',
      template: ({ wrongDate }) => moment(wrongDate).format(DATE_FORMAT.dob),
    },
    {
      header: 'Suggested date',
      template: ({ suggestedDate }) => moment(suggestedDate).format(DATE_FORMAT.dob),
    },
  ],
  sapExport: {
    comparisonByMonths: [
      {
        header: 'Month',
        template: 'month',
      },
      {
        header: 'Phoenix invoices',
        template: 'invoices',
      },
      {
        header: 'SAP export invoices',
        template: 'sapExport',
      },
      {
        header: 'Missing',
        template: ({ invoices, sapExport }) => invoices - sapExport,
      },
    ],
    missingInvoices: [
      {
        header: 'Invoice',
        component: PermissionLink,
        props: alert => ({
          class: 'emobg-font-weight-semibold',
          href: `/invoicing/invoices/${alert.invoiceId}/summary/details`,
          target: '_blank',
          linkPermissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
          text: alert.reference,
        }),
      },
      {
        header: 'Date',
        template: ({ date }) => moment(date).format(DATE_FORMAT.dob),
      },
    ],
  },
  invoicesKmsMismatch: [
    {
      header: 'Booking',
      component: PermissionLink,
      props: alert => ({
        to: {
          name: CarsharingRouterMap.bookings.detail.index,
          params: { bookingUuid: alert.bookingUuid },
        },
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
        text: `#${alert.bookingId}`,
        class: 'd-block text-ellipsis emobg-font-weight-semibold',
      }),
    },
    {
      header: 'Real end date',
      template: ({ endDate }) => moment(endDate).format(DATE_FORMAT.defaultExtended),
    },
    {
      header: 'Invoices',
      collapsed: true,
      component: InvoicesListTemplate,
      props: ({ invoices = [] }) => ({
        invoices,
      }),
    },
    {
      header: 'Booking kms',
      template: 'bookingKms',
    },
    {
      header: 'Invoiced kms',
      template: 'invoicedKms',
    },
  ],
  invoicesWithChargeBacks: [
    {
      header: 'User',
      component: PermissionLink,
      props: alert => {
        const to = alert.userUuid
          ? { name: CrmRouterMap.users.detail.index, params: { userUuid: alert.userUuid } }
          : null;
        const href = alert.userUuid || `/user-company/companies/${alert.companyId}`;
        const target = alert.userUuid || '_blank';
        const linkPermissions = [alert.userUuid ? CRM_PERMISSIONS.viewCrmUsers : CRM_PERMISSIONS.viewCrmCompanies];
        return {
          class: 'emobg-font-weight-semibold',
          to,
          href,
          target,
          linkPermissions,
          text: alert.customerName,
        };
      },
    },
    {
      header: 'Invoice',
      component: PermissionLink,
      props: invoiceProp,
    },
    {
      header: 'Date',
      template: ({ date }) => moment(date).format(DATE_FORMAT.dob),
    },
    {
      header: 'Amount',
      template: 'amount',
    },
  ],
};
